
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import AnimatedNumber from 'vue-number-animation/Number.vue'
import { SavingsCounterEmits, SavingsCounterProps } from '~/components/SavingsCounter/types'

@Component({
  components: {
    AnimatedNumber,
  },
})
export default class SavingsCounter extends Vue implements SavingsCounterProps, SavingsCounterEmits {
  @Prop({ required: true, type: Number })
  readonly orders!: number

  @Prop({ required: true, type: Number })
  readonly savings!: number

  @Prop({ required: false, type: Number, default: 3 })
  readonly animationDuration!: number

  @Prop({ required: false, type: Boolean })
  readonly oneCounterMode!: boolean

  @Prop({ required: false, type: Boolean })
  readonly darkMode!: boolean

  thousandsFormatter (value: number) {
    return value.toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '\'')
  }

  @Emit('orders-counter-animation-complete')
  onOrdersAnimationComplete () {
    return this.orders
  }

  @Emit('savings-counter-animation-complete')
  onSavingsAnimationComplete () {
    return this.savings
  }
}
