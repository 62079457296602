
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import Feature from '~/components/sections/Feature.vue'

export interface FeaturesBannerItem {
  icon: string,
  title: string,
  description: string,
}

@Component({
  components: {
    VueSlickCarousel,
    Feature,
  },
})
export default class FeaturesBanner extends Vue {
  @Prop({ required: true, type: Array })
  private readonly items!: FeaturesBannerItem[]

  @Prop({ required: false, type: Boolean, default: false })
  private readonly onlyMobile!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  private readonly rounded!: boolean

  get isSliderView () {
    return this.$mqUtils.isMobile
  }
}
